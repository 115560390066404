.project-main-hero-section {
    width: 100%;
    min-height: 100vh;
    padding: auto 0;
  }

  @media only screen and ((max-width: 420px)) {
    .project-main-hero-section {
     margin: 5rem 0 ;
     
      min-height: fit-content;
      
    }
    }
  