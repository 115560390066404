.works-container {
  width: 100%;
  align-items: flex-start;
  margin-top: 6rem;
}

.works-container-pane {
  width: 50%;
}
.works-container-left {
  margin-right: 2rem;
}
.works-container-right {
  margin-left: 2rem;
}

@media only screen and ((max-width: 420px)) {
  .works-container {
    flex-wrap: wrap;
  }
  .works-container-pane {
    width: 100%;
  }
  .works-container-left {
    margin-right: 0;
  }
  .works-container-right {
    margin-left: 0;
  }
}
