.transition-container{
    width: 100%;
    
}
.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform-origin: bottom;
    background: black;
    z-index: 1000;
  }
  
  .slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform-origin: top;
    background: black;
    z-index: 1000;
  }
  