.contact-section-container {
  width: 100%;
  align-items: flex-start;
  margin-top: 4rem;
}
.contact-big-text {
  font-size: 13rem;
}
.contact-bottom {
  width: 100%;
}

.contact-button {
  width: 70%;
  padding: 1rem;
  font-size: var(--text-normal);
  border: 1px solid var(--bg-white);
  margin-top: 2rem;
}
@media only screen and (max-width: 1024px) {
  .contact-button {
    padding: 0.8rem;
    font-size: var(--text-small);
    margin-top: 1rem;
  }
}
@media only screen and ((max-width: 420px)) {
  .contact-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
  }
  .contact-big-text {
    margin-bottom: 2rem;
  }
  .contact-bottom {
    flex-wrap: wrap;
  }
}
