.single-project-container{
  width: 100%;
}
.single-project-hero-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  /* background-color: rebeccapurple; */
  padding: 10vh 0 0 0;
}

.image-slide-image {
  object-fit: cover;
  position: absolute;
  top: 20%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
}

.single-project-hero-top {
  width: 100%;
  height:32vh;

  
  /* background-color: red; */
}
.single-project-hero-section-right {
  height: 100%;

}

.single-project-content-section {
  margin-top: 30vh;
  /* background-color: aqua; */
}



.single-project-description-container {
  width: 100%;
  height: fit-content;
  padding: 5rem 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* flex-wrap: wrap; */
  /* background-color: green; */
}

.single-project-description-paragraph {
  min-width: 300px;
  max-width: 60%;
  /* background-color: bisque; */
}

.single-project-description-paragraph-text {
  line-height: calc(var(--text-normal) + 1rem);
}

.single-project-description-tech {
  display: flex;
  align-items: start;
  justify-content: end;
  flex-wrap: wrap;
  /* max-width: 30%; */
  /* background-color: red; */
}
.single-project-description-tech-text {
  margin-left: 2rem;
  margin-bottom: 2rem;
  /* background-color: rebeccapurple; */
}
.single-project-information-container{
  margin-top: 15vh;
}
.single-project-information-card{
  margin-bottom: 5rem;
  /* background-color: red; */
}
.single-project-information-card-left{
  max-width: 50%;

}

.single-project-information-card-right{
  max-width: 500px;
}
.single-project-contributor-container{
  width: 100%;
}
.single-project-contributor-list-item {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.5px solid var(--text-white);
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.single-project-navigation-container{
  /* background-color: red; */
  /* opacity: 0.3; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.single-project-contributor-list-item-right{
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
flex-direction: column;
}
.single-project-contributor-list-item-role-desc{
  text-align: end;
}

@media only screen and (max-width: 1024px) {
  .single-project-information-card-left{
    max-width: 40%;
  
  }
}

@media only screen and (max-width: 420px) {
  .single-project-information-card-left{
    max-width: 100%;
  
  }
  .single-project-description-paragraph {
    margin-bottom: 4rem;
   text-align: center;
    /* background-color: bisque; */
  }
  .single-project-information-card{
    margin-bottom: 2rem;
    /* background-color: red; */
  }
  .single-project-description-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
    /* background-color: green; */
  }
  
  .single-project-description-tech {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 100%;
    /* background-color: red; */
  }
  .single-project-description-tech-text {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    /* background-color: rebeccapurple; */
  }
  .single-project-information-card-right{
    width: 100%;
  }
  .single-project-contributor-list-item {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
  }
  .single-project-contributor-list-item-left {
    max-width: 100%;
   margin-bottom: 2rem;
  }
  .single-project-contributor-list-item-right{
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .single-project-contributor-list-item-role-desc{
    text-align: start;
  }
  .single-project-navigation-container{
    /* background-color: red; */
    /* opacity: 0.3; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .single-project-navigation-link{
    margin-bottom: 3rem;
  }
  
}
