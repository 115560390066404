.navbar-container {
  padding-top: 1rem;
  width: 100%;
  top: 0;
}
.navbar-container .navbar-hamburger {
  width: 2rem;
  padding: 0;
  cursor: pointer;
}
.navbar-container .navbar-hamburger .hamburger-lines {
  margin: 0;
  width: 100%;
  height: 1px;
  display: inline-block;
  background-color: var(--bg-white);
}

