* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;
}

::-webkit-scrollbar {
  display: none;
}

/* cursor styles */
.cursor {
  width: 24px;
  height: 24px;
  background: #f7f7f7;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: 500ms cubic-bezier(0.22, 1, 0.36, 1);
  transition-property: border, opacity, background-color, transform,
    mix-blend-mode;
}

.cursor--hidden {
  opacity: 0;
}
.cursor--clicked {
  transform: translate(-50%, -50%) scale(0.9);
  background-color: #fefefe;
}
.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(3);
  /* background-color: transparent; */
  border: 1px solid #f7f7f7;
}

body {
  font-family: "akira";
  letter-spacing: 2px;
  width: 100%;
  background-color: var(--bg-dark);
  color: var(--text-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.end-column {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
.start-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.wrap{
  flex-wrap: wrap;
}
.wrap-reverse{
  flex-wrap: wrap-reverse;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-even {
  justify-content: space-evenly;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.direction-row-reverse{
 
 flex-direction: row-reverse;
}
.textlink {
  text-decoration: none;
  color: var(--text-white);
}
.font-xsmall-brooklyn {
  font-size: var(--text-xsmall);
  font-family: "brooklyn";
  font-weight: 500;
  letter-spacing: 0;
}
.font-small-brooklyn {
  font-size: var(--text-small);
  font-family: "brooklyn";
  font-weight: 500;
  letter-spacing: 0;
}
.font-normal-brooklyn {
  font-size: var(--text-normal);
  font-family: "brooklyn";
  font-weight: 500;
  letter-spacing: 0;
}
.width-100{
  width: 100%;
}
