.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: black;
}
.loader-p{
    font-size: var(--text-small);
    margin-top: 1rem;
}