.parent {
  position: relative;
}
.image-intersection {
  position: absolute;
  top: 40%;
  width: 100%;
  right: 0;
}

.image-reveal-overLay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: cubic-bezier(0.6, 0.01, -0.05, 0.95);
}

.image-reveal-image {
  width: 100%;
}
