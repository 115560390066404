.image-carousel-container {
  position: relative;
}

.image-carousel-image {
  width: auto;
  max-height: 300px;
  margin-right: 1rem;
}
.image-caraousel-clip-path {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--bg-dark);
  height: 100%;
  width: 100%;
  z-index: 100;
}
.clip-path-top {
  clip-path: ellipse(50% 20% at 50% 0%);
}
.clip-path-bottom {
  clip-path: ellipse(50% 20% at 50% 100%);
}
@media only screen and ((max-width: 420px)) {
  .image-carousel-image {
    max-height: 200px;
  }
}
