.navpage-container{
    
    height: calc(100vh - 6rem);
    width: 100%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navpage-container-right{
   /* background-color: red; */
}
.navpage-container-left{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    max-height: 400px;
    min-height: 400px;
    
}
.navpage-container-left-sub{
    font-size: var(--text-normal);
    font-family:"brooklyn";
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom:1rem ;
}
.mesh-background{
    z-index: -2;
    position: absolute;
    height: 500px;
    width: 500px;
    background: radial-gradient(at 50% 50%, rgba(84, 50, 195, 0.354) 20px, transparent 70%) ;
}
.nav-links{
    margin: 1rem 0;
    margin-bottom: 3rem;
    opacity: 0.3;
    transition: 300ms cubic-bezier(0.22, 1, 0.36, 1);
}
.nav-links:hover{
    transform: translateX(-1rem);
    opacity:1;
}

@media only screen and (max-width: 1024px) {
    .navpage-container-left-sub{
        font-size: var(--text-small);
        margin-bottom:0.8rem ;
    }
  }
  @media only screen and (max-width: 420px) {
    
    .navpage-container{
        justify-content: center;
    }
   
  }