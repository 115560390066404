.error-container {
  height: calc(100vh - 18rem);
}
.error-404 {
  font-size: 10rem;
  margin-bottom: 2rem;
  text-align: center;
}
.error-text {
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
}
.error-sub-text {
  font-size: 1rem;
  text-align: center;
}

@media only screen and ((max-width: 420px)) {
  .error-404 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  .error-text {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    line-height: 3rem;
  }
  .error-sub-text {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
}
