.work-experience-container {
  position: relative;
  /* background-color: var(--bg-white); */
  width: calc(100%);
  padding: 2rem 0rem;
  height: fit-content;
  /* min-height: 100vh; */
  margin-top: 5rem;
  justify-content: flex-start;
}
.background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-white);
  z-index: -2;
}
.reverse-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-dark);
  z-index: -1;
}
.intersection {
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  z-index: 1;
}
.reverse-intersection {
  position: absolute;
  top: 80%;
  right: 0;
  width: 100%;
  z-index: 2;
}



.work-experience-top {
  width: 100%;

  margin: 2rem 0 4rem 0;
}
.work-experience-top-text {
  text-align: center;
  font-size: var(--text-large);
  max-width: 100%;
  min-width: 200px;
  line-height: calc((var(--text-large) + 1rem));
  mix-blend-mode: difference;
}
.work-experience-top-text-span {
  -webkit-text-stroke: 1px var(--bg-white);
  color: transparent;
  /* mix-blend-mode: difference; */
}
.experience-container {
  min-height: 70vh;
}
.experience-role {
  min-width: 300px;
}
.experience {
  align-items: start;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 0.5px solid var(--bg-dark); */
  padding: 2rem 0;
  margin: 1rem;
  position: relative;
  transform-origin: left;
}
.experience-left {
  align-items: start;
  height: 100%;
}

.experience-span {
  height: 0.5px;
  display: inline-block;
  width: 100%;
  mix-blend-mode: difference;
  background-color: white;
  position: absolute;
  bottom: 0;
}

.experience-company {
  max-width: 30%;
  align-items: flex-end;
}

@media only screen and (max-width: 1024px) {
  .hero-section-container-bottom-mid-text {
    max-width: 300px;
  }
  .experience-company {
    max-width: 40%;
  }
}
@media only screen and ((max-width: 420px)) {
  .work-experience-container {
    margin-top: 0rem;
    justify-content: flex-start;
  }
  .work-experience-top {
    margin: 0rem 0 2rem 0;
  }
  .work-experience-top-text {
    text-align: start;
    line-height: calc((var(--text-sub-heading) + 1rem));
  }
  .experience{
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .experience-company {
    max-width: 100%;
    align-items: center;
  }
  .experience-left {
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
  }
  .experience-role {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2rem;
  }
}
