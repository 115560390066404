.homepage-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.secondary-loading {
  width: 100%;
  height: 100vh;
}
.homepage-moreprojects {
  /* background-color: rebeccapurple; */
  width: 100%;
  height: auto;
}
.homepage-moreprojects-text {
  font-size: var(--text-heading);
  text-align: center;
  margin: 5rem 0;
  line-height: calc(var(--text-heading) + 3rem);
}
.more-projects-button {
  padding: 1rem 3rem;
  border: 1px solid var(--text-white);
  margin-bottom: 5rem;
}

/* hero section */

@media only screen and ((max-width: 420px)) {
  .more-projects-button {
    text-align: center;
    font-size: var(--text-small);
  }
}
