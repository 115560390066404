.contact {
  height: calc(100vh - 5rem);
  width: 100%;
  /* background-color: red; */
}
.form {
  width: 100%;
  height: 100%;
}
.input-div {
  width: 100%;
}
.label {
  font-size: var(--text-sub-heading);
  color: var(--bg-dark);
}
.input-feild {
  width: 100%;
  height: 3rem;
  padding: 1rem;
  margin-left: 2rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--bg-dark);
}
.text-div {
  width: 100%;
  min-height: 30vh;
}
.text-feild {
  min-width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background-color: transparent;
  height: 100%;
  border: 1px solid var(--bg-dark);
}
.input-feild:focus,
.text-feild:focus {
  outline: none;
}
.submit-button {
  padding: 1rem 2rem;
  background-color: transparent;
  color: var(--bg-dark);
  font-family: "akira";
  font-size: var(--text-medium);
  border: 1px solid var(--bg-dark);
}
.single-project-navigation-container {
  height: 40vh;
}
.single-project-navigation-text {
  margin: 0 1rem;
  font-size: var(--text-sub-heading);
}
.arrow-svg {
  transform: rotateZ(-90deg);
}
