.hero-section-container {
  width: 100%;
  min-height: 90vh;
}
.mesh-background-home {
  z-index: -2;
  position: absolute;
  height: 400px;
  width: 400px;
}

.purple {
  background: radial-gradient(
    at 50% 50%,
    rgba(84, 50, 195, 0.354) 20px,
    transparent 70%
  );
}
.green {
  transform: translateX(200px);
  background: radial-gradient(
    at 50% 50%,
    rgba(50, 171, 195, 0.354) 20px,
    transparent 70%
  );
}

.hero-section-container-top {
  width: 100%;
  margin-bottom: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hero-section-container-top-profile {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  overflow: hidden;
}

.hero-section-container-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.hero-section-container-bottom-sub {
  align-items: flex-start;
}

.hero-section-container-bottom-mid {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  min-height: 230px;
}
.hero-section-container-bottom-right {
  min-height: 230px;
}

.hero-section-container-bottom-mid-text {
  font-family: "brooklyn";
  font-size: var(--text-xsmall);
  font-weight: 500;
  letter-spacing: 0;
  max-width: 400px;
  line-height: calc(var(--text-xsmall) + 1rem);
  text-transform: uppercase;
  margin-bottom: 1.2rem;
}

.hero-links {
  max-width: fit-content;
  margin-bottom: 1.2rem;
  transition: 1 cubic-bezier(0.6, 0.01, -0.05, 0.95);
}

.hero-links-span {
  display: inline-block;
  width: 0;
  background-color: white;
  height: 0.5px;
  transition: 1 cubic-bezier(0.6, 0.01, -0.05, 0.95);
  margin-bottom: 0;
  margin-top: 0;
}

.hero-links:hover .hero-links-span {
  width: 100%;
  transition: 1 cubic-bezier(0.6, 0.01, -0.05, 0.95);
}

@media only screen and (max-width: 1024px) {
  .hero-section-container-bottom-mid-text {
    max-width: 300px;
  }
}

@media only screen and ((max-width: 420px)) {
  .mesh-background-home {
    z-index: -2;
    position: absolute;
    height: 100px;
    width: 100px;
  }
  .hero-section-container-top {
    width: 100%;
    flex-direction: column-reverse;
    align-items: start;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  .hero-section-container-top-profile {
    margin-bottom: 2rem;
    height: 4rem;
    width: 4rem;
  }
  .hero-section-container-bottom {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .hero-section-container-bottom-mid {
    margin-bottom: 3rem;
    width: 100%;
    min-height: 200px;
  }

  .hero-section-container-bottom-mid-text {
    max-width: 100%;
  }
}
