:root {
    /* background color */
    --bg-dark: #121212;
    --bg-white : #FFFFFF;
    --bg-green-pastel: #BDCEAF;
    --bg-red-pastel: #CEAFAF;

    /* text color */
    --text-white: #ffffff;
    --text-dark: #121212;
    
    /* other colors */
    --violet: #6052CE;
    --cyan: #52CEB0;
    --orange: #FFCC69;
    --green: #DEF599;
    --soft-peach: #F2EFEA;
    --marble-blue: #557373;
    --old-lace: #FFF2E5;
    --egg-plant : #380D30;
    --link-water: #DFE5F3;
    --dark-brown: #272401;

    /* text */
    --text-large : 5rem;
    --text-heading : 2.5rem;
    --text-sub-heading : 1.8rem;
    --text-medium: 1.2rem;
    --text-normal: 1rem;
    --text-small: 0.8rem;
    --text-xsmall: 0.7rem;
    

}