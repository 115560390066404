.single-work-container {
  width: 100%;
  height: auto;
  margin-bottom: 4rem;
}
.single-work-about {
  margin-bottom: 2rem;
  text-align: start;
  width: 100%;
  margin-top: 2rem;
  line-height: 2rem;
}
.arrow-background {
  height: 75px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
}
.single-work-link {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media only screen and (max-width: 1024px) {
}
@media only screen and ((max-width: 420px)) {
  .single-work-about {
    text-align: center;
  }
  .single-work-link {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
