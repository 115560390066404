.footer-container{
    width: 100%;
    margin-top: 4rem;
}
.footer-span{
    display: inline-block;
    width: 100%;
    height: 0.5px;
    margin-bottom: 4rem;
    background-color: var(--bg-white);
}
.footer-content{
    margin-bottom: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
@media only screen and (max-width: 420px) {
    .contact-button {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;
    }
    .footer-content{
       
        justify-content: center;
        flex-direction: column;
        
    }
  }