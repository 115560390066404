.tool-section-container {
  width: 110%;
  position: relative;
  margin-top: 6rem;
}

.tools-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-dark);
  z-index: -1;
}
.tools-intersection {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
}

.marquee-text {
  font-size: var(--text-large);
  margin: 0 1rem;
}

@media only screen and (max-width: 1024px) {
  .marquee-text {
    font-size: var(--text-heading);
    margin: 0 1rem;
  }
}
@media only screen and ((max-width: 420px)) {
  .tool-section-container {
    margin-top: 2rem;
  }
  .marquee-text {
    font-size: var(--text-sub-heading);
    margin: 0 1rem;
  }
}
