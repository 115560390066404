.layout {
  width: 100%;
  /* background-color: var(--bg-green-pastel); */
  align-items: flex-start;
  overflow: hidden;
}
.layout-padding {
  max-width: 1400px;
  width: 95%;
  padding: 0rem 2rem 1rem 2rem;
}

@media only screen and ((max-width: 420px)) {
  .layout-padding {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
  }
}
