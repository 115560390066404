
.category {
  align-items: start;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 0.5px solid var(--bg-dark); */
  padding: 2rem 0;
  margin: 1rem;
  position: relative;
  transform-origin: left;
}
.category-left {
  align-items: start;
  height: 100%;
  display: flex; justify-content: flex-start;
  flex-direction: column;
}

.category-span {
  height: 0.5px;
  display: inline-block;
  width: 100%;
  background-color: var(--bg-dark);
  position: absolute;
  bottom: 0;
}

.category-type {
  max-width: 40%;
  align-items: flex-end;
}
@media only screen and ((max-width: 420px)) {
  .category {
   flex-direction: column;
    justify-content: center;
    /* border-bottom: 0.5px solid var(--bg-dark); */
    padding: 0rem 0;
    margin: 1rem;
    
  }
  .category-left {
   flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
  }
  .category-type {
    max-width: 100%;
    align-items: center;
    margin-bottom: 2rem;
  }
  .category-description{
    font-size: var(--text-xsmall);
  }
  }

