@font-face {
    font-family: brooklyn;
    src: url("../assets/Brooklyn/bold.otf") format("opentype");
    font-weight: bold;
  }
  @font-face {
    font-family: brooklyn;
    src: url("../assets/Brooklyn/heavy.otf") format("opentype");
    font-weight: bolder;
  }
  @font-face {
    font-family: brooklyn;
    src: url("../assets/Brooklyn/normal.otf") format("opentype");
    font-weight: 500;
  }
  @font-face {
    font-family: brooklyn;
    src: url("../assets/Brooklyn/semibold.otf") format("opentype");
    font-weight: 700 ;
  }
  @font-face {
    font-family: akira;
    src: url("../assets/Akira/akira.otf") format("opentype");
  }